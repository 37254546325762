import React, {useContext, useEffect} from 'react';
import MenuEstatico from "../elementos/Menu"
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {
    Guests as guestsContext,
    Eventos as eventosContext,
    Auth as authContext 
} from '../context';
import Modal from '../componentes/invitaciones/Modal'
import { Button, Grid, MenuItem, Snackbar, Modal as ModalMui, Menu } from '@mui/material';
import { useState } from 'react';
import { Close, Edit, Link, MoreVert, Visibility } from '@mui/icons-material';
import QR from '../componentes/invitaciones/QR';
import { Boton, Tabla, Tabla_body, Tabla_col, Tabla_h, Tabla_head, Tabla_row, TituloModulo } from '../componentes';

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Apellidos',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'reserved',
    numeric: true,
    disablePadding: false,
    label: 'Reservados',
  },
  {
    id: 'confirmation',
    numeric: true,
    disablePadding: false,
    label: 'Confirmación',
  },
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Ver código',
  },
  {
    id: 'delete',
    numeric: true,
    disablePadding: false,
    label: '',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (<TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{color: 'black'}}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <h3 className="selectTable" sx={{color: 'black'}}>{headCell.label}</h3>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Invitaciones
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const INVITACIONES = () =>{
  const [total_invitaciones, setSumaInvitaciones] = useState(0)
  const [open_confirmation, setOpen_Confirmation] = useState(false)
  const [inv_to_delete, set_inv_to_delete] = useState("")
  const invitadoContext = useContext(guestsContext);  
  const [rows, setRows] = useState([])
  const [filtrado, setFiltrado] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [edit_data, setEdit_data] = useState(null);
  const editHandleOpen = () => setOpenEdit(true);
  const editHandleClose = () => setOpenEdit(false);
  const openMenu = Boolean(anchorEl);
  const menuHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const close_menu = () => {
    setAnchorEl(null);
  };
  const {
    invitados,
    obtenerInvitados,
    guardarInvitado,
    eliminarInvitado,
    modificarInvitado
    // modificarInvitados
  } = invitadoContext;

  
  const authsContext = useContext(authContext);
  const { usuario } = authsContext;

  const EventosContext = useContext(eventosContext);
  const { evento, eventos } = EventosContext;
  

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if(reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(()=>{
    if(evento && usuario?._id){
        obtenerInvitados({userId: usuario._id, idEvento: evento});
    }
  },[evento, usuario]);

  // useEffect(()=>{
  //   if(eventos.length > 0 && invitados.length > 0){
  //     if(!evento){
  //       let firstEvent = eventos[0];
  //     }
  //   }
  // }, [eventos]);

  useEffect(()=>{
    let tt_ = 0
    invitados.map(invitado_ => {
      if(invitado_.reserved > 0){
        tt_ = tt_ + invitado_.reserved
      }
    })

    setTimeout(()=>{setSumaInvitaciones(tt_)},3000)

    if(invitados.length > 0 && Array.isArray(invitados)){
      let array = [];
      invitados.toReversed().map((invitado, i) => {
        array = [
          ...array,
          createData(
            i,
            invitado.reserved, 
            invitado.name, 
            invitado.number, 
            invitado.confirm,
            invitado.noConfirm, 
            invitado.code, 
            invitado.createAt,
            invitado._id,
            invitado.idEvento,
          )
        ]
      })

      setRows([...array])
    }
    //eslint.disable-next-line
  }, [invitados]);

  useEffect(()=>{
    if(usuario){
        obtenerInvitados({userId: usuario._id, idEvento: evento});
    }
    if(evento){ filtrar_evento(evento) }
    return;
  },[evento])

  useEffect(()=>{
    if(evento) filtrar_evento(evento)
    return;
  },[rows])


  const filtrar_evento = (evento_) => {
    if(evento_){
      setFiltrado(rows.filter(row=> evento_ ===row.idEvento))
    }
  }

  const fechaFormato = (fecha) => {
    let date = new Date(fecha)

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    return `${day}-${month}-${year}`
  }

  const handleDelete = (row) => {
    modificarInvitado({
      _id: row._id,
      confirm: 0
    });
  }

  const on_change_row = (row, value) =>{
    modificarInvitado({
      _id: row._id,
      confirm: value
    });
  }

  const createData = (id, reserved, name, number, confirm, noConfirm, code, createAt, _id, idEvento) => {
    return { id, reserved, name, number, confirm, noConfirm, code, createAt, _id, idEvento };
  }


  const copiarLink = (code, m = false) => {
    var aux = document.createElement("input");
    aux.setAttribute("value", `${process.env.REACT_APP_FRONT_URL}login/${m?"m/":""}${code}`);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    handleClick()
  }

  const change_edit_data = e => {
    setEdit_data({
      ...edit_data,
      [e.target.name]: e.target.value
    })
  }

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

    return(<MenuEstatico title='Invitaciones' acciones={(<Modal 
        evento={evento}
        titulo="Nueva Invitación"
        guardarInvitado={guardarInvitado}
        usuario={usuario}
        msgBtn="Crear invitación"
        eventos={eventos}
/>  )}>
    <Box>
        <Grid container>
            <Grid item xs={6}>
            {/* <Tooltip title={total_invitaciones} placement="left-end">
                <TituloModulo>Invitaciones</TituloModulo> 
            </Tooltip> */}
            </Grid>
            <Grid item xs={6} sx={{display: "flex", justifyContent: "end", alignSelf: "center"}}>       
            </Grid>
        </Grid>
        <Tabla>
        <Tabla_head>
            <Tabla_h>Invitado(s)</Tabla_h>
            <Tabla_h align="right">Reservados</Tabla_h>
            <Tabla_h align="right">Número</Tabla_h>
            <Tabla_h align="center">Confirmación</Tabla_h>
            <Tabla_h align="right">Asistencia</Tabla_h>
            <Tabla_h align="right">Link</Tabla_h>
            <Tabla_h align="right">Link Manual</Tabla_h>
            <Tabla_h align="right">QR</Tabla_h>
            <Tabla_h align="right">Ver code</Tabla_h>
            <Tabla_h align="right">fecha de creación</Tabla_h>
            <Tabla_h align="right"></Tabla_h>
            </Tabla_head>
            <Tabla_body>
            {filtrado.map((row, i) => (
                <Tabla_row key={i}>
                    <Tabla_col component="th" scope="row">{row.name}</Tabla_col>
                    <Tabla_col align="right">{row.reserved}</Tabla_col>
                    <Tabla_col align="right">{row.number}</Tabla_col>
                    <Tabla_col align="center">
                    <select
                        sx={{ m: 1, minWidth: 120 }}
                        size="small"
                        defaultValue={row.confirm > 0 ? row.confirm == 1010 ? 1010: row.reserved: row.confirm}
                        onChange={e=>on_change_row(row, e.target.value)}
                    >
                        <option value={0}>Pendiente</option>
                        <option value={row.reserved}>Confirmado</option>
                        <option value={1010}>Rechazado</option>
                    </select>
                    </Tabla_col>
                    <Tabla_col align="right">{row?.confirm != 0 && row?.confirm != 1010 ? row.confirm: "No aplica"}</Tabla_col>
                    <Tabla_col align="right">
                    <Link sx={{cursor: "pointer", "&:hover": {
                        color:"gold !important"
                    }}} onClick={()=>copiarLink(row.code)}/>
                    </Tabla_col>
                    <Tabla_col align="right">
                    <Link sx={{cursor: "pointer", "&:hover": {
                        color:"gold !important"
                    }}} onClick={()=>copiarLink(row.code,true)}/>
                    </Tabla_col>
                    <Tabla_col align="right">
                    <QR code={`${process.env.REACT_APP_FRONT_URL}codigo/${row.code}`}/>
                    </Tabla_col>
                    <Tabla_col align="right">
                    <Tooltip title={row.code}>
                        <Visibility sx={{cursor: "pointer"}}/>
                    </Tooltip>
                    </Tabla_col>
                    <Tabla_col align="right">{fechaFormato(row.createAt)}</Tabla_col>
                    <Tabla_col align="right">
                    <MoreVert
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        sx={{cursor:"pointer", fontSize: "15px"}}
                        onClick={e=>{
                        setEdit_data(row)
                        menuHandleClick(e)
                        }}
                    />
                        <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={close_menu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        >
                        <MenuItem sx={{height: "25px"}} onClick={e=>{
                            close_menu()
                            editHandleOpen()
                        }}>
                            <Edit sx={{fontSize: "15px"}}/>
                            <p style={{fontSize: "12px", marginLeft: "5px"}}>Editar</p>
                        </MenuItem>
                        <MenuItem sx={{height: "25px"}} onClick={e=>{
                            close_menu()
                            setOpen_Confirmation(true)
                            set_inv_to_delete(edit_data._id)
                        }}>
                            <DeleteIcon sx={{fontSize: "15px"}}/>
                            <p style={{fontSize: "12px", marginLeft: "5px"}}>Eliminar</p>
                        </MenuItem>
                        </Menu>
                    </Tabla_col>
                    </Tabla_row>
                ))}
            </Tabla_body>
        </Tabla>
        <Typography sx={{color:"#eef2f6", marginTop: "10px"}}>t</Typography>
        <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Link copiado"
        action={action}
        />
        <ModalMui
        open={open_confirmation}
        onClose={()=>setOpen_Confirmation(false)}
        aria-labelledby="ModalMui-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography sx={{fontSize: "18px"}}>
            ¿Desea <b>Eliminar</b> la invitación?
            </Typography>
            <Typography sx={{fontSize: "12px"}}>
            Al eliminar la invitación, desaparecera del inventario del evento <b>¿Desea continuar?</b>
            </Typography>
            <Box sx={{display: "grid", justifyContent: "end", marginTop: "20px"}}>
            <Box sx={{display: "flex"}}>
                <Boton onClick={()=> {
                setOpen_Confirmation(false)
                set_inv_to_delete("")
                }}>Cancelar</Boton>
                <Boton onClick={()=>{
                if(inv_to_delete){eliminarInvitado(inv_to_delete)}
                setOpen_Confirmation(false)
                set_inv_to_delete("")
                }} sx={{marginLeft:"10px"}}>Aceptar</Boton>
            </Box>
            </Box>
        </Box>
        </ModalMui>
        <ModalMui
        open={openEdit}
        onClose={editHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style_edit}>
            <p style={{
            fontSize: "20px",
            fontWeight: "bold"
            }}>Editar Invitación</p>
            <div>
            <p style={{margin: "0px"}}>Invitados:</p>
            <input
                name="name"
                style={{fontSize: "12px !important", padding: "5px 10px", minWidth: "500px"}}
                defaultValue={edit_data?.name ? edit_data.name: ""} type="name"
                onChange={change_edit_data}
            />
            </div>
            <Grid container>
            <Grid item xs={12}>
                <div style={{
                display: "flex"
                }}>
                <div>
                    <p style={{margin: "0px"}}>Reservados:</p>
                    <input
                    style={{fontSize: "12px !important", textAlign: "center", maxWidth: "80px", padding: "5px 10px",}}
                    defaultValue={edit_data?.reserved ? edit_data.reserved: ""}
                    type="number"
                    name="reserved"
                    onChange={change_edit_data}
                    />
                </div>
                <div style={{marginLeft: "10px"}}>
                    <p style={{margin: "0px"}}>Asistencia:</p>
                    <input
                    name="confirm"
                    onChange={change_edit_data}
                    style={{fontSize: "12px !important", textAlign: "center", maxWidth: "80px", padding: "5px 10px",}}
                    defaultValue={edit_data?.confirm ? edit_data.confirm: 0}
                    type="number"
                    />
                </div>
                </div>
            </Grid>
            </Grid>
            <div>
            <p style={{margin: "0px"}}>Número:</p>
            <input
                name="number"
                onChange={change_edit_data}
                style={{fontSize: "12px !important", maxWidth: "280px", padding: "5px 10px",}}
                defaultValue={edit_data?.number ? edit_data.number: ""}
                type="name"
            />
            </div>
            <Grid container>
            <Grid item xs={12} sx={{
                display: "grid",
                justifyContent: "end"
            }}>
                <div style={{display: "flex"}}>
                <input value="Cancelar" type="button" style={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    border: "none",
                    outline: "none",
                    fontSize: "12px",
                    cursor: "pointer",
                    marginRight: "10px",
                    borderRadius: "8px",
                }} onClick={editHandleClose}/>
                <input value="Guardar" type="button" style={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    border: "none",
                    outline: "none",
                    fontSize: "12px",
                    cursor: "pointer",
                    borderRadius: "8px",
                }} onClick={e=>{
                    modificarInvitado(edit_data);
                }}/>
                </div>
            </Grid>
            </Grid>
        </Box>
        </ModalMui>
    </Box>
  </MenuEstatico>)
}

export default INVITACIONES;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const style_edit = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  borderRadius: "8px",
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 1,
  px: 3,
  pb: 3,
  "& input":{
    fontSize: "12px !important"
  }
};
