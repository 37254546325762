/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect } from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import MenuEstatico from "../elementos/Menu"
import {
    Eventos as eventosContext,
    Solicitudes as SolicitudesContext
} from '../context';
import { 
    BotonGuardar,
    Tabla,
    Tabla_body,
    Tabla_col,
    Tabla_h,
    Tabla_head,
    Tabla_row
} from '../componentes';

const SOLICITUDES = () => {    
    const EventosContext = useContext(eventosContext);
    const { evento } = EventosContext;

    const solicitudesContext = useContext(SolicitudesContext);
    const { solicitudes, obtener_solicitudes, exportar_solicitudes } = solicitudesContext;
    
    useEffect(()=>{
        obtener_solicitudes(evento)
    },[evento])

    return(<MenuEstatico title='Solicitudes' acciones={(<BotonGuardar sx={{
        fontSize: "12px !important",
        textAlign: "center"
    }} onClick={e=>{
        if(evento){ exportar_solicitudes(evento) }
    }}>
        Importar Solicitudes
    </BotonGuardar>)}>
        <Box sx={{position: "relative"}}>
            <Tabla>
                <Tabla_head>
                    <Tabla_h>No.</Tabla_h>
                    <Tabla_h>Familia</Tabla_h>
                    <Tabla_h>Reservados</Tabla_h>
                    <Tabla_h align="right">Fecha</Tabla_h>
                </Tabla_head>
                <Tabla_body>{
                    solicitudes?.length > 0 ? solicitudes.map((solicitud, i_solicitud) => {
                        if(solicitud?.n_status > 0){
                            return null
                        }
                        return(<Tabla_row key={i_solicitud}>
                            <Tabla_col component="th" scope="row">{i_solicitud+1}</Tabla_col>
                            <Tabla_col component="th" scope="row">{solicitud.s_name}</Tabla_col>
                            <Tabla_col component="th" scope="row">{solicitud.n_reserved}</Tabla_col>
                            <Tabla_col component="th" scope="row">{solicitud.dt_createAt}</Tabla_col>
                        </Tabla_row>)

                    }): null
                }</Tabla_body>
            </Tabla>
            <Box sx={{
                position: "absolute",
                top: 10,
                right: 0
            }}>
                {/* <Modal
                    evento={evento}
                    titulo="Crear Pase"
                    guardarInvitado={guardar_pases}
                    usuario={null}
                    msgBtn="Crear pase"
                    eventos={pases}
                /> */}
            </Box>
        </Box>
    </MenuEstatico>)
}

export default SOLICITUDES;