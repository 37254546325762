import React, { useReducer } from 'react';
import seccionesReducer from './reducer';
import {Secciones as seccionesContext}  from '../index';
import clientAxios from '../../config/axios';
import { OBTENER_SECCIONES } from '../../types'

const MultimediaState = props => {
    const initialState = {
        secciones: []
    }
    const [state, dispatch] = useReducer(seccionesReducer, initialState)

    const guardarSecciones = async (secciones, evento) =>{
        try{
            await clientAxios.post("/api/secciones", {secciones: secciones, evento: evento})
        } catch(err){
            console.log(err)
        }
    }

    const obtenerSecciones = async (evento) =>{
        try{
            const res = await clientAxios.get(`/api/secciones/${evento}`)
            
            dispatch({
                type: OBTENER_SECCIONES, 
                payload: res.data.result
            })
    
        } catch(err){
            console.log(err)
        }
    }

    const eliminarSeccion = async _id =>{
        try{
            await clientAxios.get(`/api/secciones/delete/${_id}`)

            
        } catch(err){
            console.log(err)
        }
    }

    return(
        <seccionesContext.Provider value={{
            secciones: state.secciones,
            guardarSecciones,
            obtenerSecciones,
            eliminarSeccion
        }}>
            {props.children}
        </seccionesContext.Provider>
    )
}

export default MultimediaState;
