/* eslint-disable import/no-anonymous-default-export */
import { EXPORTAR_SOLICITUDES, GUARDAR_SOLICITUDES, OBTENER_SOLICITUDES  } from '../../types'

export default (state, action) => {
    switch(action.type){
        case OBTENER_SOLICITUDES:
            return {
                ...state,
                solicitudes: action.payload
            }
        case GUARDAR_SOLICITUDES:
            return {
                ...state,
                solicitudes: [
                    ...state.solicitudes,
                    action.payload
                ]
            }
        case EXPORTAR_SOLICITUDES:
            return {
                ...state,
                solicitudes: []
            }
        default:
            return state;
    }
}