/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Grid2, styled } from '@mui/material';
import {Eventos as eventosContext} from '../context';
import { BotonGuardar, Bloque, Titulo } from '../componentes/index';
import MenuEstatico from "../elementos/Menu";
import { formatInputDate } from '../elementos/formatDate';
import ColorPicker from 'react-pick-color';

const Configuraciones = props => {
    const EventosContext = useContext(eventosContext);
    const { modificarEvento, evento, eventos } = EventosContext;
    const [form, setForm] = useState({})
    const [first, setFirst] = useState({})
    const [update, setUpdate] = useState(false)

    function definitiveComparasion( obj1, obj2 ){
        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);
        if ( keys1.length !== keys2.length ) return false;
        for(let key__ of keys1) {
            let val1 = obj1[key__];
            let val2 = obj2[key__];

            if ( val1 !== val2 ) return false;
        }

        return true;
    }

    useEffect(()=>{
        if(evento || evento != ""){
            let data_evento = eventos.filter(ev => ev._id == evento)
            if(data_evento?.length > 0){
                let res_filter = data_evento[0]
                setForm({
                    ...form,
                    ...res_filter,
                    hashtag: res_filter?.hashtag ? res_filter.hashtag: "",
                    n_cuenta: res_filter?.n_cuenta ? res_filter.n_cuenta: ""
                })

                setFirst({
                    ...form,
                    ...res_filter,
                    hashtag: res_filter?.hashtag ? res_filter.hashtag: "",
                    n_cuenta: res_filter?.n_cuenta ? res_filter.n_cuenta: ""
                })
            }
        }
    },[evento, eventos])

    useEffect(()=>{
        let res_comparative = definitiveComparasion(form, first)
        setUpdate(!res_comparative)
        console.log(form)
        
    },[form])

    const guardar_evento = () => {
        modificarEvento({_id: evento, ...form})
        setUpdate(false)
        setFirst(form)
    }

    const onChange = e => {
        if(
            e.target.name === "padre"       ||
            e.target.name === "madre"       ||
            e.target.name === "padreNovia"  ||
            e.target.name === "padreNovio"  ||
            e.target.name === "madreNovio"  ||  
            e.target.name === "madreNovia"  
        ){
            setForm({
                ...form,
                padres:{
                    ...form.padres,
                    [e.target.name] : e.target.value
                }
            })
        }else if(
            e.target.name === "edad"        || 
            e.target.name === "festejado"   ||
            e.target.name === "nombreCorto" ||
            e.target.name === "novio"       ||
            e.target.name === "novia"
        ){
            setForm({
                ...form,
                festejados:{
                    ...form.festejados,
                    [e.target.name] : e.target.value
                }
            })
        }else{
            if(e.target.name==="tipo" && e.target.value == 1){
                setForm({
                    ...form,
                    [e.target.name] : e.target.value,
                    festejados: {...form.festejados, edad: 15}
                })    
            }else{
                setForm({
                    ...form,
                    [e.target.name] : e.target.value
                })
            }
        }
    }

    return (<MenuEstatico title='Configuraciones' acciones={update ? 
        (<Box sx={{display: "grid", alignContent: "start", justifyContent: "end"}}>
            <BotonGuardar sx={{fontSize: "12px"}} onClick={guardar_evento}>Guardar</BotonGuardar>
        </Box>): null}>
        <Bloque className="scrollBloque">
            <Grid2 spacing={2} container sx={{ width: "100%"}}>
                <Grid2 size={{xs: 12, sm: 12, md: 8, lg: 8}}>
                    <Texto>Nombre del evento</Texto>
                    <Campo
                        id="nombre"
                        name="nombre"
                        value={form?.nombre ? form.nombre: ""}
                        onChange={e=>setForm({
                            ...form,
                            nombre: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Fecha del evento</Texto>
                    <Campo
                        type='date'
                        id="dtEvento"
                        name="dtEvento"
                        value={form?.dtEvento ? formatInputDate(form.dtEvento): formatInputDate(Date.now())}
                        onChange={e=>{
                            var utcSeconds = 60*60*6;
                            let date_epoch = new Date(e.target.value)
                            date_epoch.setUTCSeconds(utcSeconds)
                            setForm({
                                ...form,
                                dtEvento: date_epoch.getTime()
                            })
                        }}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Nombre Corto</Texto>
                    <Campo
                        id="nombre_corto"
                        name="nombre_corto"
                        value={form?.festejados?.nombreCorto ? form.festejados.nombreCorto: ""}
                        onChange={e=>setForm({
                            ...form,
                            festejados: {
                                ...form.festejados,
                                nombreCorto: e.target.value
                            }
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 0,  sm: 0, md: 8, lg: 8}}/>
                <Grid2 size={{xs: 12, sm: 12, md: 2, lg: 2}}>
                    <Texto>Tipo de evento</Texto>
                    <Select
                        disabled
                        id="tipo"
                        name="tipo"
                        value={form.tipo}
                        onChange={e=>{
                            setForm({
                                ...form,
                                tipo: e.target.value
                            })
                        }}
                    >
                        <Option value={0}>Boda</Option>
                        <Option value={1}>XV</Option>
                        <Option value={2}>Cumpleaños</Option>
                        <Option value={3}>Bautizo</Option>
                        <Option value={4}>BabyShower</Option>
                    </Select>
                </Grid2>
                {form?.tipo===0?(<Grid2 size={{xs: 12, ls: 12, md:12}}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{xs: 12, ls: 12, md:6}}>
                            <Texto>Nombre del Novio</Texto>
                            <Campo
                                id="novio"
                                name="novio"
                                value={form?.festejados?.novio ? form.festejados.novio: ""}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:6}}>
                            <Texto>Nombre del Novia</Texto>
                            <Campo
                                id="novia"
                                name="novia"
                                value={form?.festejados?.novia ? form.festejados.novia: ""}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:6}}>
                            <Texto>Padre del Novio</Texto>
                            <Campo
                                id="padreNovio"
                                name="padreNovio"
                                value={form?.padres?.padreNovio ? form.padres.padreNovio: ""}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:6}}>
                            <Texto>Madre del Novio</Texto>
                            <Campo
                                id="madreNovio"
                                name="madreNovio"
                                value={form?.padres?.madreNovio ? form.padres.madreNovio: ""}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:6}}>
                            <Texto>Padre de la Novia</Texto>
                            <Campo
                                id="padreNovia"
                                name="padreNovia"
                                value={form?.padres?.padreNovia ? form.padres.padreNovia: ""}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:6}}>
                            <Texto>Madre de la Novia</Texto>
                            <Campo
                                id="madreNovia"
                                name="madreNovia"
                                value={form?.padres?.madreNovia ? form.padres.madreNovia: ""}
                                onChange={onChange}
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>):(<Grid2 size={{xs: 12, ls: 12, md:12}}>
                    <Grid2 container spacing={4}>
                        <Grid2 size={{xs: 12, ls: 12, md:4}}>
                            <Texto>Nombre del festejado</Texto>
                            <Campo
                                id="festejado"
                                name="festejado"
                                value={form?.festejados?.festejado ? form.festejados.festejado: ""}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:4}}>
                            <Texto>Nombre del Padre</Texto>
                            <Campo
                                id="padre"
                                name="padre"
                                value={form?.padres?.padre ? form.padres.padre: ""}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:4}}>
                            <Texto>Nombre del Madre</Texto>
                            <Campo
                                id="madre"
                                name="madre"
                                value={form?.padres?.madre ? form.padres.madre: ""}
                                onChange={onChange}
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>)}
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Número de invitados</Texto>
                    <Campo
                        id="invitados"
                        name="invitados"
                        type='number'
                        value={form?.invitados? form.invitados: ""}
                        onChange={e=>setForm({
                            ...form,
                            invitados: parseInt(e.target.value)
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 0,  sm: 0, md: 8, lg: 8}}/>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Número de cuenta</Texto>
                    <Campo
                        type='number'
                        id="numero_cuenta"
                        name="numero_cuenta"
                        value={form?.n_cuenta ? parseInt(form.n_cuenta): ""}
                        onChange={e=>setForm({
                            ...form,
                            n_cuenta: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 0,  sm: 0, md: 8, lg: 8}}/>
                <Grid2 size={{xs: 12, ls: 12, md:5, lg: 5}}>
                    <Texto>Salón</Texto>
                    <Campo
                        id="salon"
                        name="salon"
                        value={form?.salon ? form.salon: ""}
                        onChange={e=>setForm({
                            ...form,
                            salon: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, ls: 12, md:5, lg: 5}}>
                    <Texto>Ubicación</Texto>
                    <Campo
                        id="maps_salon"
                        name="maps_salon"
                        value={form?.maps_salon ? form.maps_salon: ""}
                        onChange={e=>setForm({
                            ...form,
                            maps_salon: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, ls: 12, md:2, lg: 2}}>
                    <Texto>Hora</Texto>
                    <Campo
                        id="hora_salon"
                        name="hora_salon"
                        value={form?.hora_salon ? form.hora_salon: ""}
                        onChange={e=>setForm({
                            ...form,
                            hora_salon: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, ls: 12, md:8, lg: 8}}>
                    <Texto>Dirección</Texto>
                    <Campo
                        id="dir_salon"
                        name="dir_salon"
                        value={form?.dir_salon ? form.dir_salon: ""}
                        onChange={e=>setForm({
                            ...form,
                            dir_salon: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 0,  sm: 0, md: 4, lg: 4}}/>
                <Grid2 size={{xs: 12, ls: 12, md:5, lg: 5}}>
                    <Texto>Iglesia</Texto>
                    <Campo
                        id="iglesia"
                        name="iglesia"
                        value={form?.iglesia ? form.iglesia: ""}
                        onChange={e=>setForm({
                            ...form,
                            iglesia: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, ls: 12, md:5, lg: 5}}>
                    <Texto>Ubicación</Texto>
                    <Campo
                        id="maps_iglesia"
                        name="maps_iglesia"
                        value={form?.maps_iglesia ? form.maps_iglesia: ""}
                        onChange={e=>setForm({
                            ...form,
                            maps_iglesia: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, ls: 12, md:2, lg: 2}}>
                    <Texto>Hora</Texto>
                    <Campo
                        id="hora_iglesia"
                        name="hora_iglesia"
                        value={form?.hora_iglesia ? form.hora_iglesia: ""}
                        onChange={e=>setForm({
                            ...form,
                            hora_iglesia: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, ls: 12, md:8, lg: 8}}>
                    <Texto>Dirección</Texto>
                    <Campo
                        id="dir_iglesia"
                        name="dir_iglesia"
                        value={form?.dir_iglesia ? form.dir_iglesia: ""}
                        onChange={e=>setForm({
                            ...form,
                            dir_iglesia: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 0,  sm: 0, md: 4, lg: 4}}/>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>#Hashtag</Texto>
                    <Campo
                        id="hashtag"
                        name="hashtag"
                        value={form?.hashtag ? form.hashtag: ""}
                        onChange={e=>setForm({
                            ...form,
                            hashtag: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Titulo del Banner</Texto>
                    <Campo
                        id="titleBanner"
                        name="titleBanner"
                        value={form?.titleBanner ? form.titleBanner: ""}
                        onChange={e=>setForm({
                            ...form,
                            titleBanner: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Frase</Texto>
                    <Campo
                        id="txtFrase"
                        name="txtFrase"
                        value={form?.txtFrase ? form.txtFrase: ""}
                        onChange={e=>setForm({
                            ...form,
                            txtFrase: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Texto Invitado</Texto>
                    <Campo
                        id="txtInvitado"
                        name="txtInvitado"
                        value={form?.txtInvitado ? form.txtInvitado: ""}
                        onChange={e=>setForm({
                            ...form,
                            txtInvitado: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Confirmación por Whatsapp</Texto>
                    <input
                        type="checkbox"
                        id="whatsapp"
                        name="whatsapp"
                        defaultChecked={form?.whatsapp ? true: false}
                        value={form?.whatsapp ? true: false}
                        onChange={e=>{
                            setForm({
                                ...form,
                                whatsapp: e.target.checked
                            })
                        }}
                    />
                    {form?.whatsapp ? (<Campo
                        id="txtWhatsapp"
                        name="txtWhatsapp"
                        value={form?.txtWhatsapp ? form.txtWhatsapp: ""}
                        onChange={e=>setForm({
                            ...form,
                            txtWhatsapp: e.target.value
                        })}
                    />): null}

                    <Texto>Teléfono 1</Texto>
                    <Campo
                        id="telefono"
                        name="telefono"
                        value={form?.telefono ? form.telefono: ""}
                        onChange={e=>setForm({
                            ...form,
                            telefono: e.target.value
                        })}
                    />
                    <Texto>Teléfono 2</Texto>
                    <Campo
                        id="telefono2"
                        name="telefono2"
                        value={form?.telefono2 ? form.telefono2: ""}
                        onChange={e=>setForm({
                            ...form,
                            telefono2: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Texto Invitado Versiculo</Texto>
                    <Campo
                        id="txtVersiculo"
                        name="txtVersiculo"
                        value={form?.txtVersiculo ? form.txtVersiculo: ""}
                        onChange={e=>setForm({
                            ...form,
                            txtVersiculo: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Texto Consideraciones 1</Texto>
                    <Campo
                        id="txtConsi1"
                        name="txtConsi1"
                        value={form?.txtConsi1 ? form.txtConsi1: ""}
                        onChange={e=>setForm({
                            ...form,
                            txtConsi1: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Texto Consideraciones 2</Texto>
                    <Campo
                        id="txtConsi2"
                        name="txtConsi2"
                        value={form?.txtConsi2 ? form.txtConsi2: ""}
                        onChange={e=>setForm({
                            ...form,
                            txtConsi2: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Texto Obsequios</Texto>
                    <Campo
                        id="txtObsequios"
                        name="txtObsequios"
                        value={form?.txtObsequios ? form.txtObsequios: ""}
                        onChange={e=>setForm({
                            ...form,
                            txtObsequios: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Texto Recuerdos</Texto>
                    <Campo
                        id="txtRecuerdos"
                        name="txtRecuerdos"
                        value={form?.txtRecuerdos ? form.txtRecuerdos: ""}
                        onChange={e=>setForm({
                            ...form,
                            txtRecuerdos: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Texto Confirmación</Texto>
                    <Campo
                        id="txtConfirmacion1"
                        name="txtConfirmacion1"
                        value={form?.txtConfirmacion1 ? form.txtConfirmacion1: ""}
                        onChange={e=>setForm({
                            ...form,
                            txtConfirmacion1: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Texto Confirmación 2</Texto>
                    <Campo
                        id="txtConfirmacion2"
                        name="txtConfirmacion2"
                        value={form?.txtConfirmacion2 ? form.txtConfirmacion2: ""}
                        onChange={e=>setForm({
                            ...form,
                            txtConfirmacion2: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Botón Confirmación 1</Texto>
                    <Campo
                        id="btnConfirmacion1"
                        name="btnConfirmacion1"
                        value={form?.btnConfirmacion1 ? form.btnConfirmacion1: ""}
                        onChange={e=>setForm({
                            ...form,
                            btnConfirmacion1: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Botón Confirmación 2</Texto>
                    <Campo
                        id="btnConfirmacion2"
                        name="btnConfirmacion2"
                        value={form?.btnConfirmacion2 ? form.btnConfirmacion2: ""}
                        onChange={e=>setForm({
                            ...form,
                            btnConfirmacion2: e.target.value
                        })}
                    />
                </Grid2>
                <Grid2 size={{xs: 0,  sm: 0, md: 8, lg: 8}}/>
                <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                    <Texto>Plantilla de la invitación</Texto>
                    <Select value={form?.id_plantilla ? form.id_plantilla: 0} onChange={e=>{
                        setForm({
                            ...form,
                            id_plantilla: parseInt(e.target.value)
                        })
                    }}>
                        <Option value={0}>Seleccionar Plantilla</Option> 
                        <Option value={1}>jaqmik</Option> 
                        <Option value={2}>adkar</Option> 
                        <Option value={3}>jeimi</Option>
                        <Option value={4}>katherine</Option>
                        <Option value={5}>marmad</Option>
                        <Option value={6}>franco</Option>
                        <Option value={7}>boda_1</Option>
                        <Option value={8}>plantilla_evento_1</Option>
                    </Select>
                </Grid2>
            </Grid2>
            <Grid2 size={{xs: 0,  sm: 0, md: 8, lg: 8}}/>
            <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}} sx={{marginTop: "10px"}}>
                <Texto>Color principal</Texto>
                <ColorPicker color={form?.color_1 ? form.color_1: "#ffffff"} onChange={color => setForm({
                    ...form,
                    color_1: color.hex
                })} />
            </Grid2>
            <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}} sx={{marginTop: "10px"}}>
                <Texto>Color Secundario</Texto>
                <ColorPicker color={form?.color_2 ? form.color_2: "#ffffff"} onChange={color => setForm({
                    ...form,
                    color_2: color.hex
                })} />
            </Grid2>
            <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}} sx={{marginTop: "10px"}}>
                <Texto>Color terciario</Texto>
                <ColorPicker color={form?.color_3 ? form.color_3: "#ffffff"} onChange={color => setForm({
                    ...form,
                    color_3: color.hex
                })} />
            </Grid2>
            <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}} sx={{marginTop: "10px"}}>
                <Texto>Color adicional 1</Texto>
                <ColorPicker color={form?.color_4 ? form.color_4: "#ffffff"} onChange={color => setForm({
                    ...form,
                    color_4: color.hex
                })} />
            </Grid2>
            <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 4}} sx={{marginTop: "10px"}}>
                <Texto>Color adicional 2</Texto>
                <ColorPicker color={form?.color_5 ? form.color_5: "#ffffff"} onChange={color => setForm({
                    ...form,
                    color_5: color.hex
                })} />
            </Grid2>
        </Bloque>
    </MenuEstatico>)
}

// nombre:             { type: String, require: true, trim: true },
// userId:             { type: String, require: true},
// invitados:          { type: Number, default: 0},
// id_plantilla:       { type: Number, default: 0},
// tipo:               { type: Number, default: 0},
// hashtag:            { type: String, default: ""},
// festejados:         { type: Object, default: {} },
// telefono:           { type: String, require: true, default: "" },
// email:              { type: String, require: true, default: "" },
// padres:             { type: Object,  default: {} },
// n_cuenta:           { type: String,  default: "" },
// iglesia:            { type: Object },
// apartados:          { type: Object,  default: {}},
// salon:              { type: Object },
// codigoVestimenta:   { type: Number, require: true },
// dtEvento:           { type: Number, default: today},
// dtCreado:           { type: Number, default: today},
// dtModificado:       { type: Number, default: today},
// eliminado:          { type: Boolean, default: false}

const Select = styled('select')({
    borderRadius: "8px",
    width: "100%",
    fontSize: "12px",
    padding: "5px 10px",
    cursor: "pointer"
})

const Option = styled('option')({
    padding: "10px !important",
    margin: "10px !important",
})


const Campo = styled('input')({
    width: "100%",
    padding: "7px 10px",
    fontSize: "12px",
    borderRadius: "5px"
})

const Texto = styled('p')({
    margin: "0px",
    fontSize: "12px"
})

export default Configuraciones;