import React, {useContext, useEffect} from 'react';
import {Alertas as alertsContext} from '../../context';
import {
    Backdrop,
    Box,
    Modal,
    Fade,
    Typography,
    TextField,
    Stack,
    Alert,
    styled
} from '@mui/material';
import { BotonGuardar } from '../index';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TransitionsModal = ({evento="", eventos=[], guardarInvitado, usuario, msgBtn = "Agregar", titulo="Nueva", fields=[]}) => {
    const alertContext = useContext(alertsContext);
    const { alert } = alertContext;

    const [guest, saveGuest] = React.useState({
        n_codigo:'',
        s_password:'',
        i_status: 0,
        idEvento: ""
    })


    useEffect(()=>{
        if(evento){
            saveGuest({...guest, idEvento: evento})
        }
    },[])

    useEffect(()=>{
        if(evento){
            saveGuest({...guest, idEvento: evento})
        }
    },[evento])

    const {idEvento, email, s_codigo, reserved, number} = guest;

    const onChange = e => {
        saveGuest({
            ...guest,
            [e.target.name] : e.target.value
        })
    }
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const onSubmit = async e =>{
        e.preventDefault();
        // Validar que no haya campos vacios
        const result = await guardarInvitado({ 
            idEvento, 
            s_codigo
        });
        if(result?.success) handleClose()
        
    }

    return (
        <div>
            <Box sx={{
                width: '100%',
                alignSelf: "center",
                textAlign: 'end',
            }}>
                <BotonGuardar sx={{
                    fontSize: "12px !important"
                }} onClick={handleOpen}>
                    {msgBtn}
                </BotonGuardar>
            </Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
            }}>
                <Fade in={open}>
                <Box sx={style}>
                    <Typography sx={{textAlign: 'center', paddingBottom: 3}} id="transition-modal-title" variant="h6" component="h2">
                        {titulo}
                    </Typography>
                    <TextField 
                        sx={{paddingBottom: 2, width: '100%'}} 
                        id="s_codigo"
                        name="s_codigo"
                        label="Dirección"
                        variant="outlined"
                        onChange={onChange}
                        value={s_codigo}
                    />
                    {/* <TextField
                        sx={{paddingBottom: 2, width: '100%'}}
                        id="email"
                        name="email"
                        label="Email" 
                        variant="outlined"
                        onChange={onChange}
                        value={email}
                    /> */}
                    {/* <FormControl fullWidth sx={{marginBottom: "20px"}}>
                        <InputLabel id="evento">Evento</InputLabel>
                        <Select
                            id="idEvento"
                            name="idEvento"
                            value={idEvento}
                            label="Evento"
                            onChange={onChange}
                        >
                            {eventos.map(evento =>(<MenuItem value={`${evento._id}`}>{evento.nombre}</MenuItem>))}
                        </Select>
                    </FormControl> */}
                    {/* <TextField
                        sx={{paddingBottom: 2, width: '100%'}}
                        id="number"
                        name="number"
                        label="Número Telefónico" 
                        variant="outlined"
                        onChange={onChange}
                        value={number}
                    /> */}
                    {/* <TextField
                        sx={{paddingBottom: 2, width: '100%'}}
                        id="reserved"
                        name="reserved"
                        label="Número de reservaciones"
                        variant="outlined"
                        onChange={onChange}
                        value={reserved}
                    /> */}
                    <Box sx={{width: '100%', textAlign: 'center', padding: '5px 0px'}}>
                        <BotonGuardar sx={{
                            fontSize: "12px",
                            justifyContent: "center"
                        }} onClick={onSubmit}>Agregar</BotonGuardar>
                    </Box>
                    {alert ? 
                        (
                            <Stack sx={{ width: '100%', paddingTop: '20px' }} spacing={2}>
                                <Alert className={`dark ${alert.category}`} severity="error">{alert.msg}</Alert>
                            </Stack>
                        )
                    : null}
                </Box>
                </Fade>
            </Modal>
        </div>
    );
}

const Boton = styled('button')({
    background: "#403000 !important",
      color: "rgb(255, 255, 255) !important",
      textTransform: "none !important",
      cursor: "pointer",
      border: "none",
      borderRadius: "5px",
      padding: "7px 18px",
      outline: "none !important",
      fontSize: "12px",
      "&:hover":{
          backgroundColor: "#d7b615 !important",
          color: "white !important",
          textShadow: "0px 5px 10px #ffeb77"
      }
  })

export default TransitionsModal;