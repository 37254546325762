import { Meses } from "./Catalogo"

const number_date = epochTime => {
    let date = new Date(epochTime)
    const day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = date.getFullYear()

    return [day, month, year]
}

export const formatDate = epochTime => {
    let fecha_num = number_date(epochTime)
    return `${fecha_num[0]}-${Meses(fecha_num[1])}-${fecha_num[2]}`;
}

export const formatInputDate = epochTime => {
    let fecha_num = number_date(epochTime)
    return `${fecha_num[2]}-${fecha_num[1]}-${fecha_num[0]}`;
}


export const DecimalYear = (fecha_1, fecha_2) => {
    return parseFloat((fecha_1-fecha_2)/(1000*60*60*24*365.25)).toFixed(2);
}

export const DecimalToMonths = (unidades) => {
    let enteros = Math.trunc(unidades), decimales = unidades - enteros;
    let decimales_mounths = decimales * 12, meses = Math.trunc(decimales_mounths);

    return `${enteros > 0 ? enteros > 1 ? `${enteros} años`: `${enteros} año`: ""} ${meses > 0 ? meses > 1 ? `${meses} meses`: `${meses} mes`: ""}`
}