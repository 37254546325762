import React, { useReducer } from 'react';
import temporalReducer from './reducer';
import {Temporal as temporalContext} from '../index';
import clientAxios from '../../config/axios';
import { OBTENER_TEMPORAL, OBTENER_TEMPORAL_ID_EVENTO} from '../../types'

const TempporalState = props => {
    const initialState = {
        invitados: [],
        idEvento: null
    }
    const [state, dispatch] = useReducer(temporalReducer, initialState)

    const obtenerInvitados = async code_pass => {
        try{
            const res = await clientAxios.post(`/api/pass/temporal/obtener/${code_pass}`)
            const result = res.data
            if(result?.code === 200){
                dispatch({
                    type: OBTENER_TEMPORAL,
                    payload: result?.guests?.length > 0 ? result.guests: []
                })

                dispatch({
                    type: OBTENER_TEMPORAL_ID_EVENTO,
                    payload: result?.idEvento ? result.idEvento: null
                })
            }
            return true
        }catch(err){
            console.log(err)
            return false
        }
    }

    return(
        <temporalContext.Provider value={{
            invitados: state.invitados,
            idEvento: state.idEvento,
            obtenerInvitados
        }}>
            {props.children}
        </temporalContext.Provider>
    )
}

export default TempporalState;
