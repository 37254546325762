// AUTHENTICATION
export const REGISTRO_EXITOSO   = "REGISTRO_EXITOSO";
export const REGISTRO_ERROR     = "REGISTRO_ERROR";
export const OBTENER_USUARIO    = "OBTENER_USUARIO";
export const LOGIN_EXITOSO      = "LOGIN_EXITOSO";
export const LOGIN_ERROR        = "LOGIN_ERROR";
export const CERRAR_SESION      = "CERRAR_SESION";
export const REGISTRO_ERROR_MSG = "REGISTRO_ERROR_MSG";
export const AGREGAR_TOKEN      = "AGREGAR_TOKEN";

// GUESTS
export const GUARDAR_INVITADO       = "GUARDAR_INVITADO";
export const OBTENER_INVITADOS      = "OBTENER_INVITADOS";
export const ELIMINAR_INVITADO      = "ELIMINAR_INVITADO";
export const MODIFICAR_INVITADOS    = "MODIFICAR_INVITADOS";

// EVENTOS
export const OBTENER_EVENTOS        = "OBTENER_EVENTOS";
export const SELECCIONAR_EVENTO     = "SELECCIONAR_EVENTO";
export const GUARDAR_EVENTO         = "GUARDAR_EVENTO";
export const ELIMINAR_EVENTO        = "ELIMINAR_EVENTO";
export const MODIFICAR_EVENTO       = "MODIFICAR_EVENTO";

// ALERTAS
export const MOSTRAR_ALERTA         = "MOSTRAR_ALERTA";
export const OCULTAR_ALERTA         = "OCULTAR_ALERTA";

// SOLICITUDES
export const EXPORTAR_SOLICITUDES   = "EXPORTAR_SOLICITUDES";
export const GUARDAR_SOLICITUDES    = "GUARDAR_SOLICITUDES";
export const OBTENER_SOLICITUDES    = "OBTENER_SOLICITUDES";

// MULTIMEDIA
export const GUARDAR_IMAGEN         = "GUARDAR_IMAGEN";
export const OBTENER_IMAGENES       = "OBTENER_IMAGENES";

// SECCIONES
export const SECCIONES_MODULO       = "SECCIONES_MODULO";
export const GUARDAR_SECCIONES      = "GUARDAR_SECCIONES";
export const OBTENER_SECCIONES      = "OBTENER_SECCIONES";

// PASES
export const GUARDAR_PASE           = "GUARDAR_PASE";
export const OBTENER_PASES          = "OBTENER_PASES";

// TEMPORALES
export const OBTENER_TEMPORAL               = "OBTENER_TEMPORAL";
export const OBTENER_TEMPORAL_ID_EVENTO     = "OBTENER_TEMPORAL_ID_EVENTO";
export const CREAR_TEMPORAL                 = "CREAR_TEMPORAL";