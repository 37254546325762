import React, {useReducer} from 'react'
import pasesReducer from './reducer'
import clientAxios  from '../../config/axios'
import {Pases as PasesContext} from '../index'

import { GUARDAR_PASE, OBTENER_PASES } from '../../types'

const PasesState = props => {
    const initialState = {
        pases: []
    }

    const [ state, dispatch ] = useReducer(pasesReducer, initialState)

    const obtener_pases = async evento => {
        try{
            console.log(evento)
            const result = await clientAxios.get(`/api/pass/temporal/pases/${evento}`)

            if(result?.status == 200){
                let data = result.data

                return dispatch({
                    type: OBTENER_PASES,
                    payload: data.pases
                })
            }
        } catch(err){
            console.log(err)
        }
    }

    const guardar_pases = async pase => {
        try{
            const result = await clientAxios.post(`/api/pass/temporal/${pase.s_codigo}`, pase)

            console.log(result)

            if(result?.status == 200){
                let result_pase = result.data 
                
                return dispatch({
                    type: GUARDAR_PASE,
                    payload: result_pase.data
                })
            }
        } catch(err){
            console.log(err)
        }
    }


    return(
        <PasesContext.Provider
            value={{
                pases: state.pases,
                obtener_pases,
                guardar_pases
            }}
        >
            {props.children}
        </PasesContext.Provider>
    )
}

export default PasesState;