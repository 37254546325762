import { Box, TextField } from "@mui/material"

export const Campos = ({
    multiline=false,
    name="",
    sx,
    rows=1,
    align="center",
    onChange,
    value,
    variant="standard",
    type="text",
    disabled=false,
    onKeyDown=null,
    InputProps={}
}) => {
    return(<Box sx={{
        "& input, textarea":{
            background: "#e8e8e8",
            border: "none",
            borderRadius: "10px",
            padding: "8px 12px",
            textAlign: align,
            fontSize: "12px"
        }
    }}>
        {onKeyDown ? (<TextField
        type={type}
        name={name}
        variant={variant}
        sx={sx}
        rows={rows}
        multiline={multiline}
        value={value}
        onKeyDown={onKeyDown}
        disabled={disabled}
        onChange={onChange}
        InputProps={{
            disableUnderline: true, // <== added this
        }}
        fullWidth/>):(<TextField
        type={type}
        name={name}
        variant={variant}
        sx={sx}
        disabled={disabled}
        rows={rows}
        multiline={multiline}
        value={value}
        onChange={onChange}
        InputProps={{
            disableUnderline: true, // <== added this
        }}
        fullWidth/>)}
    </Box>)
}