import { Box, Grid2 } from "@mui/material";
import MenuEstatico from "../elementos/Menu"
import { BotonGuardar, Tabla, Tabla_body, Tabla_col, Tabla_h, Tabla_head, Tabla_row } from "../componentes";
import { useEffect, useState } from "react";
import { AddCircle, ArrowLeft, ArrowRight } from "@mui/icons-material";

const Cuentas = () => {
    const [bloques, setBloques] = useState([])
    const [page, setPage] = useState(0)

    useEffect(()=>{
        console.log(page)
    },[page])

    return(<MenuEstatico title="Cuentas" acciones={(<Box>
        <AddCircle
            onClick={e=>{
                setBloques([
                    ...bloques,
                    [{_id: "ldksldklskad"}]
                ])
            }}
            sx={{
                color: "#2da6ff",
                cursor: "pointer",
                textShadow: "0px 0px 3px black"
            }}
        />
    </Box>)}>
        <Box sx={{display: "flex", justifyContent: "end", marginBottom: "15px"}}>
            <Box onClick={e=> {
                if(page > 0){
                    setPage(page - 1)
                }
            }} sx={{border: "1px solid #a1a1a1", height: "25px", borderRadius: "4px",  marginRight: "10px", cursor: "pointer"}}>
                <ArrowLeft/>
            </Box>
            <Box onClick={e=>{
                console.log(bloques?.length, page)
                if(bloques?.length > page+1){
                    setPage(page+1)
                }
            }}
                sx={{border: "1px solid #a1a1a1", height: "25px", borderRadius: "4px", cursor: "pointer"}}>
                <ArrowRight/>
            </Box>
        </Box>
        <Grid2 container spacing={2}>
            {bloques?.length > 0 ? bloques.map((item, key_item) => {
                return(<Grid2 key={key_item} sx={{display: 
                    page !== 0 ? page === key_item ? "grid": page+1 === key_item ? "grid": page-1 === key_item ? "grid": "none": page+1 === key_item || page+2===key_item || key_item === 0 ? "grid": "none"
                }} size={{xs: page === key_item ? 6: 3}}>
                    <Tabla>
                        <Tabla_head>
                            <Tabla_h>Descripción</Tabla_h>
                            <Tabla_h>Cantidad</Tabla_h>
                            <Tabla_h>Monto</Tabla_h>
                            <Tabla_h>Fecha</Tabla_h>
                        </Tabla_head>
                        <Tabla_body>
                            <Tabla_row>
                                <Tabla_col>dklkdlkfd</Tabla_col>
                                <Tabla_col>dklkdlkfd</Tabla_col>
                                <Tabla_col>dklkdlkfd</Tabla_col>
                                <Tabla_col>dklkdlkfd</Tabla_col>
                            </Tabla_row>
                        </Tabla_body>
                    </Tabla>
                    {/* {page === key_item ? (<Box>
                        <BotonGuardar>lcklzckxl</BotonGuardar>
                    </Box>): null} */}
                </Grid2>)})
            : null}
        </Grid2>
    </MenuEstatico>)
}

export default Cuentas;