import { Box, Button, Grid, IconButton, Link, Modal, Snackbar, TextField, Tooltip, styled } from "@mui/material"
import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import {Temporal as TemporalContext, Solicitudes as SolicitudesContext} from "../context";
import EnhancedTable from "../componentes/paseTemporal/Tabla";
import TablaSolicitudes from "../componentes/paseTemporal/TablePases";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import ClockIcon from '@mui/icons-material/QueryBuilder';
import DangerousIcon from '@mui/icons-material/Dangerous';
import img_url from '../images/convitte.png';
import SearchIcon from '@mui/icons-material/Search';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { BarChart, PieChart } from '@mui/x-charts';
import Error404 from "../componentes/paseTemporal/Error404";
import { AddBox, Close, LinkOutlined } from "@mui/icons-material";

function createData(id, name, calories, fat, carbs, ausencias, protein, url, search) {
    return {
        id,
        name,
        calories,
        fat,
        carbs,
        ausencias,
        protein,
        url,
        search
    };
}

const PaseTemporal = () => {
    const temporalContext = useContext(TemporalContext);
    const { obtenerInvitados, invitados, idEvento } = temporalContext;

    const solicitudesContext = useContext(SolicitudesContext);
    const { guardar_solicitudes, obtener_solicitudes, solicitudes } = solicitudesContext;

    const [rows, setRows]                   = useState([])
    const [data, setData]                   = useState([])
    const [total, setTotal]                 = useState(0)
    const [total_confirmados, setTotalConf] = useState(0)
    const [total_ausencias, setTotalAus]    = useState(0)
    const [total_pendiente, setTotalPend]   = useState(0)
    const [i_t_cnf, setCnf]                 = useState(0)
    const [i_t_aus, setAus]                 = useState(0)
    const [i_t_pen, setPen]                 = useState(0)
    const [phrase, setPhrase]               = useState("")
    const [openGraph, setOpenGraph]         = useState(false)
    const [openSolicitud, setOpenSolicitud] = useState(false)
    const [loading, setLoading]             = useState(false)
    const [openSnack, setOpenSnack]             = useState(false)
    
    
    const [solicitudes_, setSolicitudes]     = useState([])
    const [form, setForm]     = useState({})

    const handleCloseSnack = () => setOpenSnack(false)

    useEffect(()=>{
        if(obtener_solicitudes){
            obtener_solicitudes(idEvento)
        }
    },[idEvento])

    useEffect(()=>{
        if(solicitudes?.length > 0){
            let new_rows_sol = solicitudes.map((solicitud, i_solicitud)=>{
                const today = new Date(solicitud.dt_createAt);
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // month is zero-based
                let dd = today.getDate();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                const formatted = dd + '/' + mm + '/' + yyyy;

                const Status = ({status}) => {
                    const sts_pen = {
                        background: "gray",
                        color: "white",
                        textAlign: "center",
                        borderRadius: "100px",
                        fontSize: "12px"
                    }

                    const sts_apr = {
                        background: "#38f558",
                        color: "white",
                        textAlign: "center",
                        borderRadius: "100px",
                        fontSize: "12px"
                    }

                    const sts_rch = {
                        background: "#fa2d37",
                        color: "white",
                        textAlign: "center",
                        borderRadius: "100px",
                        fontSize: "12px"
                    }

                    const sts_err = {
                        background: "#1c0f26",
                        color: "white",
                        textAlign: "center",
                        borderRadius: "100px",
                        fontSize: "12px"
                    }

                    switch(status){
                        case 0:     return(<Box sx={sts_pen}>Pendiente</Box>)
                        case 1:     return(<Box sx={sts_apr}>Aprobado</Box>)
                        case 2:     return(<Box sx={sts_rch}>Rechazado</Box>)
                        default:    return(<Box sx={sts_err}>Error</Box>)
                    }
                }
                
                return createData(
                    i_solicitud+1,
                    (<TextAll>{solicitud.s_name}</TextAll>),
                    (<TextAll>{solicitud.n_reserved}</TextAll>),
                    (<Status status={solicitud.n_status}/>),
                    (<TextAll>{formatted}</TextAll>),
                )
            })

            setSolicitudes(new_rows_sol)
        }
    },[solicitudes])
    
    const [dataseries, setDataseries] = useState([
        { label: 'Confirmadas', value: 0 },
        { label: 'Rechazadas',  value: 0 },
        { label: 'Pendientes',  value: 0 },
    ]);

    let { code_guest } = useParams();
    
    const cargar_data = async () => {
        console.log(code_guest)
        let result_request = await obtenerInvitados(code_guest)
    
        if(result_request){setLoading(true)}
        else{setLoading(false)}
    }

    useEffect(()=>{cargar_data()},[])

    const TextAll = styled('p')({
        margin: "0px",
        padding: "0px",
        fontSize: "12px"
    })
    
    const Status = ({confirm=0, reserved=0}) => {
        let color = "#dddddd"
        let text = "Pendiente"
        let Icon = null
        let ausencias = 0
        
        const Tag = styled('p')({
            margin: "0px",
            width: "fit-content",
            fontSize: "12px",
            marginLeft: "7px"
        })

        if(confirm > 0){
            if(confirm == 1010){
                text = `Rechazada`
                color="#ff8f8f"
                Icon = (<DangerousIcon sx={{fontSize: "15px", alignSelf: "center"}}/>)
                ausencias = reserved
            }else{
                if(confirm != reserved){
                    text = `${confirm} confirmados`
                    ausencias = reserved - confirm
                    color="#ffeab1"
                    Icon = (<WarningAmberIcon sx={{fontSize: "15px", alignSelf: "center"}}/>)
                }else{
                    text = `${confirm} confirmados`
                    color="#daffda"
                    Icon = (<CheckIcon sx={{fontSize: "15px", alignSelf: "center"}}/>)
                }
            }
        }else{
            color="#dddddd"
            Icon = (<ClockIcon sx={{fontSize: "15px", alignSelf: "center"}}/>)
        }

        return <div style={{
            padding: "2px 10px",
            background: color,
            borderRadius: "8px",
            display: "flex"
        }}>
            {ausencias > 0 ? (<Tooltip title={`${ausencias} ausencia(s)`}>
                <div style={{display: "flex"}}>
                    {Icon ? Icon: null}
                    <Tag>{text}</Tag>
                </div>
            </Tooltip>):(<div style={{display: "flex"}}>
                {Icon ? Icon: null}
                <Tag>{text}</Tag>
            </div>)}
        </div>
    }

    const AuNumber = ({confirm=0, reserved=0}) => {
        let ausencias = 0
        const Tag = styled('p')({
            margin: "0px",
            width: "fit-content",
            fontSize: "12px",
            textAlign: "center",
            width:"100%",
            marginLeft: "7px"
        })

        console.log(confirm, reserved)
        if(confirm > 0){
            if(
                confirm == 1010){ ausencias = reserved
            }else{
                if(confirm != reserved){ ausencias = reserved - confirm}
            }
        }

        return <Tag>{ausencias != 0? ausencias: "-"}</Tag>
    }

    const copiarLink = (code, m = false) => {
        var aux = document.createElement("input");
        aux.setAttribute("value", `${process.env.REACT_APP_FRONT_URL}login/${m?"m/":""}${code}`);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        setOpenSnack(true);
    }

    useEffect(()=>{
        if(invitados?.length > 0){
            let total_invitados = 0, totalConfirmados = 0, totalPendientes = 0, totalAusencias = 0
            let t_cnf = 0, t_aus = 0, t_pen = 0
            let new_rows = invitados.map((invitado, i_invitado)=>{
                total_invitados = total_invitados + invitado.reserved
                if(invitado.confirm != 1010){
                    t_cnf = t_cnf + 1
                    totalConfirmados = totalConfirmados + invitado.confirm
                    let difAusencias = invitado.reserved - invitado.confirm
                    if(invitado.confirm > 0){
                        totalAusencias = totalAusencias + difAusencias
                    }
                }else{
                    totalAusencias = totalAusencias + invitado.reserved
                    t_aus = t_aus + 1
                }

                if(invitado.confirm == 0){
                    totalPendientes = totalPendientes + invitado.reserved
                    t_pen = t_pen + 1
                }

                const today = new Date(invitado.createAt);
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // month is zero-based
                let dd = today.getDate();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                const formatted = dd + '/' + mm + '/' + yyyy;
                
                console.log(invitado)

                return createData(
                    i_invitado+1,
                    (<TextAll>{invitado.name}</TextAll>),
                    (<Status confirm={invitado.confirm} reserved={invitado.reserved}/>),
                    (<TextAll sx={{fontWeight: "bold"}}>{invitado.reserved}</TextAll>),
                    (<TextAll>{invitado.confirm != 1010 && invitado.confirm != 0? invitado.confirm: "-"}</TextAll>),
                    (<AuNumber confirm={invitado.confirm} reserved={invitado.reserved}/>),
                    (<TextAll>{formatted}</TextAll>),
                    (<LinkOutlined sx={{cursor: "pointer", color:"black", "&:hover": {
                        color:"gold !important"
                      }}} onClick={()=>copiarLink(invitado.code)} />),
                    invitado.name
                )
            })

            setData(new_rows)
            setTotal(total_invitados)
            setTotalConf(totalConfirmados)
            setTotalPend(totalPendientes)
            setTotalAus(totalAusencias)

            setAus(t_aus)
            setCnf(t_cnf)
            setPen(t_pen)
        }
    },[invitados])

    useEffect(()=>{
        setDataseries([
            { label: 'Confirmadas', value: i_t_cnf },
            { label: 'Rechazadas',  value: i_t_aus },
            { label: 'Pendientes',  value: i_t_pen },
        ])
    },[i_t_aus, i_t_cnf, i_t_pen])

    useEffect(()=>{
        if(phrase != ""){
            let search_result = data.filter(item => item.search.toLowerCase().lastIndexOf(phrase) > -1)
            setRows(search_result)
        }else{
            setRows(data)
        }
    },[phrase])

    useEffect(()=>{
        setRows(data)
    },[data])

    const headCells = [
        {
          id: 'name',
          numeric: false,
          disablePadding: true,
          label: 'Invitado (Nombre)',
        },{
          id: 'calories',
          disablePadding: true,
          label: 'Estatus',
          align: "center"
        },{
          id: 'fat',
          numeric: true,
          disablePadding: false,
          label: 'Invitados',
          align: "right"
        },{
          id: 'carbs',
          numeric: true,
          disablePadding: false,
          label: 'Confirmaciones',
          align: "right"
        },{
            id: 'ausencias',
            disablePadding: false,
            label: 'Inasistencias',
            align: "center"
        },{
            id: 'url',
            disablePadding: false,
            label: 'Url',
            align: "center"
        },{
          id: 'protein',
          numeric: true,
          disablePadding: false,
          label: 'Fecha de Creación',
          align: "right"
        },
    ];

    const headCellsSolicitudes = [
        {
            id: 's_name',
            numeric: false,
            disablePadding: true,
            label: 'Invitado (Nombre)',
        },{
            id: 'n_reserved',
            numeric: true,
            disablePadding: false,
            label: 'Reservaciones',
            align: "right"
        },{
            id: 'n_status',
            numeric: true,
            disablePadding: false,
            label: 'Estatus',
            align: "right"
        },{
            id: 'dt_createAt',
            numeric: true,
            disablePadding: false,
            label: 'Fecha de Creación',
            align: "right"
        }
    ]

    const TextRight = styled('p')({
        margin: "0px",
        fontSize: "18px",
        textAlign: "end"
    })

    const TextTitle = styled('p')({
        fontSize: "15px",
        margin: "0px",
        fontWeight: "bold",
        minHeight: "45px"
    })

    const Text = styled('p')({
        margin: "0px",
    })

    const chartSetting = {
        xAxis: [
          {
            label: 'Invitados',
          },
        ],
        width:400,
        height:300
      };

    const handle_change = (name, value) => {
        setForm(old_form =>{
            old_form[name] = value
            return old_form
        })
    }

    const action = (<Fragment>
            <Button color="secondary" size="small" onClick={handleCloseSnack}>
            UNDO
            </Button>
            <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
            >
            <Close fontSize="small" />
            </IconButton>
    </Fragment>);

    const Form = () =>  (<Box sx={{ padding: "20px" }}>
        <Grid container>
            <Grid item xs={12} md={2} sm={2}>
                <div style={{
                    background: "white",
                    padding: "10px 15px",
                    borderRadius: "8px",
                    width: "fit-content",
                    marginTop: "10px"
                }}>
                    <img src={img_url} width="50px" height="50px"/>
                </div>
            </Grid>
            <Grid sx={{justifyContent: "right", display: "grid"}} item xs={12} sm={10} md={10}>
                <div
                    id="scroll-table-pass-temporal" 
                    style={{
                        display: "flex",
                        marginBottom: "10px",
                        paddingBottom: "10px",
                        marginTop: "10px"
                }}>
                    <div style={{
                        background: "white",
                        padding: "10px 15px",
                        borderRadius: "8px"
                    }}>
                        <TextTitle>Invitados Pendientes</TextTitle>
                        <TextRight>{total_pendiente}</TextRight>
                    </div>
                    <div style={{
                        background: "white",
                        padding: "10px 15px",
                        borderRadius: "8px",
                        marginLeft: "10px"
                    }}>
                        <TextTitle>Invitados Confirmados</TextTitle>
                        <TextRight>{total_confirmados}</TextRight>
                    </div>
                    <div style={{
                        background: "white",
                        marginLeft: "10px",
                        padding: "10px 15px",
                        borderRadius: "8px"
                    }}>
                        <TextTitle>Inasistencias</TextTitle>
                        <TextRight>{total_ausencias}</TextRight>
                    </div>
                    <div style={{
                        background: "white",
                        padding: "10px 15px",
                        borderRadius: "8px",
                        marginLeft: "10px"
                    }}>
                        <TextTitle>Total Invitados</TextTitle>
                        <TextRight>{total}</TextRight>
                    </div>
                </div>
            </Grid>
        </Grid>
        <div style={{
            background: "white",
            margin: "0px !important",
            marginTop: "5px",
            borderRadius: "8px 8px 0px 0px",
            padding: "10px"
        }}>
            <Grid container>
                <Grid item xs={5}>
                    <Text sx={{fontSize: "18px", fontWeight: "500"}}>Invitaciones</Text>
                </Grid>
                <Grid item xs={7} sx={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "end",
                    "& input": {
                        padding: "0px !important",
                        border: "none !important"
                    }
                }}>
                    <TextField sx={{border: "none", padding: "0px !important", fontSize: "12px"}} InputProps={{
                        style: {
                            fontSize: "12px",
                            padding: "3px",
                            borderRadius: "8px"
                        },
                        padding: "0px Qimportant",
                        startAdornment: <SearchIcon sx={{
                            margin: "0px",
                            fontSize: "18px",
                            marginRight: "3px",
                            marginLeft: "1px",
                            color: "#8f8f8f !important"
                        }}/>, // <== adjusted this
                        disableUnderline: true, // <== added this
                    }} value={phrase} onChange={e=>setPhrase(e.target.value)}/>
                    <EqualizerIcon sx={{
                        marginLeft: "10px",
                        cursor: "pointer"
                    }} onClick={e=> setOpenGraph(true)}/>
                </Grid>
            </Grid>
        </div>
        <EnhancedTable rows_data={rows} headCells={headCells}/>


        <div style={{
            background: "white",
            margin: "0px !important",
            marginTop: "45px",
            borderRadius: "8px 8px 0px 0px",
            padding: "10px"
        }}>
            <Grid container>
                <Grid item xs={5}>
                    <Text sx={{fontSize: "18px", fontWeight: "500"}}>Solicitudes</Text>
                </Grid>
                <Grid item xs={7} sx={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "end",
                    "& input": {
                        padding: "0px !important",
                        border: "none !important"
                    }
                }}>
                    <TextField sx={{border: "none", padding: "0px !important", fontSize: "12px"}} InputProps={{
                        style: {
                            fontSize: "12px",
                            padding: "3px",
                            borderRadius: "8px"
                        },
                        padding: "0px Qimportant",
                        startAdornment: <SearchIcon sx={{
                            margin: "0px",
                            fontSize: "18px",
                            marginRight: "3px",
                            marginLeft: "1px",
                            color: "#8f8f8f !important"
                        }}/>, // <== adjusted this
                        disableUnderline: true, // <== added this
                    }} value={phrase} onChange={e=>setPhrase(e.target.value)}/>
                    <AddBox sx={{
                        marginLeft: "10px",
                        cursor: "pointer"
                    }} onClick={e=> setOpenSolicitud(true)}/>
                </Grid>
            </Grid>
        </div>
        <TablaSolicitudes
            rows_data={solicitudes_}
            headCells={headCellsSolicitudes}
        />
        <Modal
            open={openGraph}
            onClose={e=>setOpenGraph(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box className="scrollBloque" sx={{ ...style, width: "60%" }}>
                <Text sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    borderBottom: "1px solid black",
                    marginBottom: "15px"
                }}>Detalles del Evento</Text>
                <Grid container>
                    <Grid item xs={6}>
                        <Text sx={{
                            fontWeight: "200",
                            fontSize: "16px"}}>Resumen de invitaciones</Text>
                    </Grid>
                    <Grid item xs={6} sx={{display: "grid", justifyContent: "end"}}>
                        <div style={{
                            background: "#cae5ff",
                            width: "fit-content",
                            padding: "2px 10px",
                            borderRadius: "8px"

                        }}>
                            <Text>Total de invitaciones ({rows?.length ? rows.length: 0})</Text>
                        </div>
                    </Grid>
                </Grid>
                <Box sx={{marginTop: "70px"}}>
                    <PieChart
                        colors={["#6d94b7", "#ad3800ad", "rgb(179 179 179)"]}
                        series={[{data: dataseries, innerRadius: 40, cornerRadius: 5, paddingAngle: 3}]}
                        width={350}
                        height={150}
                    />
                </Box>
                <Grid container sx={{marginTop: "100px"}}>
                    <Grid item xs={6} sx={{display: "grid", alignContent: "center"}}>
                        <Text sx={{
                            fontWeight: "200",
                            fontSize: "16px"
                        }}>Resumen de invitados</Text>
                    </Grid>
                    <Grid item xs={6} sx={{display: "grid", justifyContent: "end"}}>
                        <div style={{
                            background: "#cae5ff",
                            width: "fit-content",
                            padding: "2px 10px",
                            borderRadius: "8px"

                        }}>
                            <Text>Total de invitados ({total})</Text>
                        </div>
                    </Grid>
                </Grid>
                <Box sx={{marginTop: "20px"}}>
                    <BarChart
                        yAxis={[{ scaleType: 'band', data: [
                            'Evento'
                        ] }]}
                        series={[
                            { data: [total_pendiente], color: "#c7c7c7", label: `Pendientes`},
                            { data: [total_ausencias], color: "#f65e5e", label: `Inasistencias`},
                            { data: [total_confirmados], color: "#6adf73", label: `Confirmados`},
                        ]}
                        layout="horizontal"
                        {...chartSetting}
                    />
                </Box>
            </Box>
        </Modal>

        <Modal
            open={openSolicitud}
            onClose={e=>setOpenSolicitud(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box className="scrollBloque" sx={{ ...style, width: "60%" }}>
                <Grid container sx={{
                    borderBottom: "1px solid black",
                    marginBottom: "15px"
                }}>
                    <Grid item xs={6}>
                        <Text sx={{
                            fontSize: "20px",
                            fontWeight: "500",
                        }}>Nueva Solicitud</Text>
                    </Grid>
                    <Grid item xs={6} sx={{display: "grid", justifyContent: "end"}}>
                        <BtnSave onClick={e=>{
                            let data_form = {
                                ...form,
                                idEvento: idEvento
                            }
                            guardar_solicitudes(data_form)
                        }}>Guardar</BtnSave>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={8}>
                        <DivTxtInput>
                            <LblTxtInput>Nombre:</LblTxtInput>
                            <TxtInput type="text" onChange={e=>handle_change("s_name", e.target.value)}/>
                        </DivTxtInput>
                        <DivTxtInput>
                            <LblTxtInput>Email: (opcional)</LblTxtInput>
                            <TxtInput type="text" onChange={e=>handle_change("s_email", e.target.value)}/>
                        </DivTxtInput>
                        <DivTxtInput>
                            <LblTxtInput>Teléfono: (opcional)</LblTxtInput>
                            <TxtInput type="text" onChange={e=>handle_change("s_number", e.target.value)}/>
                        </DivTxtInput>
                    </Grid>
                    <Grid item xs={4}>
                        <DivTxtInput sx={{margin: "0px 20px"}}>
                            <LblTxtInput>Reservaciones:</LblTxtInput>
                            <TxtInput onChange={e=>handle_change("n_reserved", e.target.value)} type="number" name="n_reserved"/>
                        </DivTxtInput>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
        <Snackbar
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            message="Url de invitación copiado"
            action={action}
        />
    </Box>)

    return(<>
        {loading == "charge" ? null: loading == false ? (<Error404/>): (<Form/>)}
    </>)
}

export default PaseTemporal

const DivTxtInput = styled('div')({
    display: "flex",
    alignItems: "center"
})

const TxtInput = styled('input')({
    fontSize: "12px",
    padding: "4px 10px",
    borderRadius: "8px",
    border: "1px solid #dbd7d7",
    outline: "none",
    height: "28px",
    width: "100%"
})

const LblTxtInput = styled('p')({
    fontSize: "12px",
    marginRight: "15px",
    fontWeight: "bold"
})

const BtnSave = styled('button')({
    borderRadius: "8px",
    fontSize: "13px",
    fontWeight: "bold",
    background: "#fb7c81",
    color: "white",
    border: "0px",
    cursor: "pointer",
    padding: "5px 10px",
    marginBottom: "10px",
    fontFamily: "sans-serif"
})

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    overflowY: "scroll",
    height: 350,
    bgcolor: 'background.paper',
    borderRadius: "8px",
    border: 'none',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };