import React, {useReducer} from 'react'
import SolicitudesReducer from './reducer'
import clientAxios  from '../../config/axios'
import {Solicitudes as SolicitudesContext} from '../index'

import { EXPORTAR_SOLICITUDES, GUARDAR_SOLICITUDES, OBTENER_SOLICITUDES } from '../../types'

const SolicitudesState = props => {
    const initialState = {
        solicitudes: []
    }

    const [ state, dispatch ] = useReducer(SolicitudesReducer, initialState)

    const obtener_solicitudes = async evento => {
        try{
            if(evento){
                const result = await clientAxios.get(`/api/solicitudes/${evento}`)
    
                if(result?.status == 200){
                    let data = result.data
    
                    return dispatch({
                        type: OBTENER_SOLICITUDES,
                        payload: data.result
                    })
                }
            }
        } catch(err){
            console.log(err)
        }
    }

    const guardar_solicitudes = async solicitud => {
        try{
            console.log(solicitud)
            const result = await clientAxios.post(`/api/solicitudes`, solicitud)

            console.log(result)

            if(result?.status == 200){
                let result_pase = result.data 

                return dispatch({
                    type: GUARDAR_SOLICITUDES,
                    payload: result_pase.solicitud
                })
            }
        } catch(err){
            console.log(err)
        }
    }

    const exportar_solicitudes = async idEvento => {
        try{
            const result = await clientAxios.get(`/api/solicitudes/export_to/${idEvento}`)
            
            if(result?.status == 200){
                return dispatch({
                    type: EXPORTAR_SOLICITUDES,
                    payload: []
                })
            }
        } catch(err){
            console.log(err)
        }
    }

    return(
        <SolicitudesContext.Provider
            value={{
                solicitudes: state.solicitudes,
                obtener_solicitudes,
                guardar_solicitudes,
                exportar_solicitudes
            }}
        >
            {props.children}
        </SolicitudesContext.Provider>
    )
}

export default SolicitudesState;