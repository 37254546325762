import React, {useReducer} from 'react'
import authReducer from './reducer'
import {Auth as AuthContext} from '../index'
import clientAxios  from '../../config/axios'
import tokenAuth from '../../config/tokenAuth'

import {
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    REGISTRO_ERROR_MSG,
    AGREGAR_TOKEN
} from '../../types'

const Auth = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        autenticado: null,
        usuario: null,
        mensaje: null,
        loading: true
    }

    const [ state, dispatch ] = useReducer(authReducer, initialState)

    const registrarUsuario = async data => {
        try{
            const result = await clientAxios.post('/api/users', data)
            dispatch({
                type: REGISTRO_EXITOSO,
                payload: result.data
            })

            usuarioAutenticado()
        } catch(err){
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alert
            })
        }
    }

    const usuarioAutenticado = async reqtoken => {
        try{
            const token = await reqtoken;
            
            if(token){
                // TODO: funcion mandar token por headers
                tokenAuth(token);
            }
            
            const result = await clientAxios.get('/api/auth'), result_data = result?.data ? result.data: null;
            if(result_data?.user){
                dispatch({
                    type: OBTENER_USUARIO,
                    payload: result_data.user
                })
            }
        } catch(err){
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }
            dispatch({
                type: LOGIN_ERROR,
                payload: alert
            })
        }
    }

    const setToken = async token => {
        if(token){
            dispatch({
                type: AGREGAR_TOKEN,
                payload: token
            })
        }
    }

    const login = async user => {
        try{
            const result = await clientAxios.post('/api/auth', user), result_data = result?.data ? result?.data: null;   
            
            if(result_data?.token){
                dispatch({
                    type: LOGIN_EXITOSO,
                    payload: result.data
                })
                usuarioAutenticado(result_data.token)
            }else{
                dispatch({
                    type: REGISTRO_ERROR_MSG,
                    payload: "Error"
                })
            }
            
        } catch(err){
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR_MSG,
                payload: alert
            })
        }
    }

    const cerrarSesion = () => {
        dispatch({
            type: CERRAR_SESION
        })
    }

    return(
        <AuthContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                mensaje: state.mensaje,
                loading: state.loading,
                setToken,
                registrarUsuario,
                usuarioAutenticado,
                login,
                cerrarSesion
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default Auth;