import { useEffect } from "react";
import "./style.css"

const Error404 = () => {
    return(<>
    <main class='container'>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>4</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <span class='particle'>0</span>
        <article class='content'>
            <p style={{fontSize: "30px", fontWeight: "bold"}}>Error 404</p>
            <p sx={{
                fontSize: "12px !important"
            }}>La dirección a la que quieres acceder es incorrecta.</p>
            <p>
            <button onClick={e=>{
                window.history.go(-1); 
                return false;
            }}>Regresar</button>
            </p>
        </article>
        </main>
    </>)
}

export default Error404