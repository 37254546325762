import { Box } from "@mui/material"
import "../css/loading.css"

export const Loading = () => {
    return(<Box sx={{
        height: "100vh",
        width: "100vw",
        display: "grid",
        justifyContent: "center",
        alignContent: "center"
    }}>
        <Box className="loader"></Box>
    </Box>)
}